
import VideoPlayIcon from "./VideoPlayIcon";
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';

const ConceptVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);
  const [showControls, setShowControls] = useState(true);
  const controlsTimeout = useRef(null);

  const { ref, inView } = useInView({
    triggerOnce: true, // Load the video only once when it comes into view
    threshold: 0.5,    // Load when 50% of the component is visible
  });


  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };
  useEffect(() => {
    const handleScroll = () => {
      const player = playerRef.current;
      if (player) {
        const boundingRect = player.wrapper.getBoundingClientRect();
        const isCompletelyOutOfView = boundingRect.bottom < 0 || boundingRect.top > window.innerHeight;
        if (isCompletelyOutOfView && isPlaying) {
          handlePause();
        } else if (!isCompletelyOutOfView && !isPlaying) {
          // handlePlay();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isPlaying]);


  const handleMouseMove = () => {
    console.log("moving ")
    // Show controls when the mouse is moved
    setShowControls(true);

    // Clear the previous timeout
    if (controlsTimeout.current) {
      clearTimeout(controlsTimeout.current);
    }

    // Hide controls after 2 seconds of inactivity
    controlsTimeout.current = setTimeout(() => {
      setShowControls(false);
    }, 2000);
  };


  useEffect(() => {
    // Clean up timeout on component unmount
    return () => {
      if (controlsTimeout.current) {
        clearTimeout(controlsTimeout.current);
      }
    };
  }, []);

  return (
    <div className="relative w-full h-auto aspect-video max-h-screen flex items-center justify-center player-wrapper " ref={ref} onMouseMove={handleMouseMove} onMouseMoveCapture={handleMouseMove} >

      {inView && <>
        <ReactPlayer
          url="https://vimeo.com/1024916389?share=copy"
          width="100%"
          height="100%"
          muted={false}
          volume={1}
          playing={isPlaying}
          className="react-player cursor-pointer "
          controls={false}
          onPause={() => {
            if (isPlaying) {
              setIsPlaying(false)
            }
          }}
          ref={playerRef}

        />
        {(showControls || !isPlaying) ? <button
          type="button"
          className="absolute top-2/4 -translate-y-2/4"
          id="playVideo"
          title="Click to play video"
          onClick={handlePlay}
        >
          <VideoPlayIcon isPlaying={isPlaying} />

          <span className="hidden">Play Video</span>
        </button> : <div className=" absolute top-[40%] w-full h-auto aspect-video max-h-screen"></div>}
      </>}


    </div>
  );
};

export default ConceptVideo;
